import { api } from '../../../services';

class SettingsService {
  constructor(api) {
    this.api = api;
  }

  getUsers = () => this.api.get('/users');

  addUsers = (payload) => this.api.post('/users', payload);

  deleteUser = (id) => this.api.delete(`/user/${id}`);
}

export const settingsService = new SettingsService(api);
