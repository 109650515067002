import { Row } from 'antd';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';

import { hoc } from '../../utils';
import { useLoginProps } from './login.props';

export const Login = hoc(useLoginProps, ({ login, loginSchema }) => {
  return (
    <Row style={{ height: '100%' }} align="middle" justify="center">
      <Formik
        initialValues={{
          username: '',
          password: '',
        }}
        validateOnBlur
        validationSchema={loginSchema}
        onSubmit={login}
        initialErrors={true}
      >
        {({ isSubmitting }) => (
          <Form
            labelCol={{
              span: 24,
            }}
            layout="vertical"
            wrapperCol={{
              span: 24,
            }}
            style={{
              width: 300,
            }}
          >
            <Form.Item
              label="Username"
              name="username"
              rules={[
                {
                  required: true,
                  message: 'Please input your username!',
                },
              ]}
            >
              <Input
                placeholder="Username"
                name="username"
                autoFocus={true}
                prefix={<UserOutlined />}
              />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
              ]}
            >
              <Input.Password
                placeholder="Password"
                name="password"
                prefix={<LockOutlined />}
              />
            </Form.Item>

            <Row justify="center">
              <SubmitButton disabled={isSubmitting}>Login</SubmitButton>
            </Row>
          </Form>
        )}
      </Formik>
    </Row>
  );
});
