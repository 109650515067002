import { LogoutOutlined } from '@ant-design/icons';
import { useAuth } from '../../../hooks';

export const useHeaderProps = () => {
  const { logout } = useAuth();
  const items = [
    {
      key: '1',
      icon: <LogoutOutlined />,
      label: 'Log out',
    },
  ];

  const onClick = logout;

  return { items, onClick };
};
