import { all } from 'redux-saga/effects';
import { commonService } from '../../services';
import { fetchSaga } from '../../utils';
import { commonActions } from './common.slice';

export function* commonRootSaga() {
  yield all([
    fetchSaga({
      pattern: 'login',
      actions: commonActions,
      api: {
        method: commonService.login,
        callback: (res) => res,
      },
    }),
  ]);
}
