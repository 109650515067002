import { useAuth } from './hooks';
import React from 'react';
import { config } from './config';
import { storageService } from './services';
import { notification } from 'antd';
import { useSelector } from 'react-redux';

export const useAppProps = () => {
  const { isAuthenticated } = useAuth();
  const token = storageService.getAccessToken();
  const error = useSelector((state) => state.common.error);
  const success = useSelector((state) => state.common.success);
  const [api, contextHolder] = notification.useNotification();

  React.useEffect(() => {
    if (success) {
      api.success({
        message: 'Success',
        description: 'Successfully saved',
      });
    }
  }, [success, api]);

  React.useEffect(() => {
    if (error) {
      api.error({
        message: 'Error',
        description: 'Something went wrong',
      });
    }
  }, [error, api]);

  React.useEffect(() => {
    if (token) {
      fetch(config.apiUrl + '/verify', {
        headers: {
          Authorization: `Bearer ${storageService.getAccessToken()}`,
        },
      });
    }
  }, [token]);

  return { isAuthenticated, contextHolder };
};
