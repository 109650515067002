import { createSlice } from '@reduxjs/toolkit';
import { storageService } from '../../services';
import { articlesActions } from '../../modules/services/store/articles.slice';

const name = 'common';

const token = storageService.getAccessToken();

const initialState = {
  token,
  success: false,
  error: false,
};

export const { actions: commonActions, reducer: commonReducer } = createSlice({
  name,
  initialState,
  reducers: {
    setLoading() {},
    setError() {},
    login() {},
    loginFulfilled(state, { payload }) {
      if (payload.token) {
        state.token = payload.token;
      }
    },
    success(state, { payload }) {
      state.success = true;
    },
    error(state, { payload }) {
      state.error = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(articlesActions.postArticleFulfilled, (state) => {
      state.success = true;
    });
  },
});
