import { api } from '../../../services';

class TrainingsService {
  constructor(api) {
    this.api = api;
  }

  getTrainings = () => this.api.get('/trainings');

  addTraining = (payload) => this.api.post('/trainings', payload);

  updateTraining = (payload) =>
    this.api.put(`/training/${payload.training_id}`, payload);

  deleteTraining = (id) => this.api.delete(`/training/${id}`);
}

export const trainingsService = new TrainingsService(api);
