import { Layout, Menu } from 'antd';
import { hoc } from '../../../utils';
import { useSidebarProps } from './sidebar.props';

const { Sider } = Layout;

export const Sidebar = hoc(
  useSidebarProps,
  ({ items, onClick, selectedKey }) => {
    return (
      <Sider>
        <div
          style={{
            padding: '15px',
            color: '#fff',
            fontWeight: 'bold',
            fontSize: '24px',
            textAlign: 'center',
            letterSpacing: '2px',
          }}
        >
          Mirpower
        </div>
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={['1']}
          items={items}
          onClick={onClick}
          selectedKeys={selectedKey}
        />
      </Sider>
    );
  }
);
