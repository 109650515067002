import { hoc } from './utils';
import { useAppProps } from './app.props';
import { Login } from './modules/login';
import { Suspense } from 'react';
import { Routes } from './routes';
import { Layout } from './components/layout';
import { Loader } from './components/loader';

export const App = hoc(useAppProps, ({ isAuthenticated, contextHolder }) => {
  if (isAuthenticated) {
    return (
      <Layout>
        <Suspense fallback={<Loader text="Loading page..." />}>
          <Routes />
        </Suspense>

        {contextHolder}
      </Layout>
    );
  }

  return <Login />;
});
