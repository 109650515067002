import React from 'react';
import { theme, Layout, Avatar, Row, Dropdown, Space } from 'antd';
import { hoc } from '../../../utils';
import { useHeaderProps } from './header.props';
import { UserOutlined } from '@ant-design/icons';

const { Header: AntHeader } = Layout;

export const Header = hoc(useHeaderProps, ({ items, onClick }) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <AntHeader
      style={{
        paddingRight: '20px',
        background: colorBgContainer,
      }}
    >
      <Row justify="end" align="middle">
        <Dropdown menu={{ items, onClick }} placement="bottomRight">
          <Space style={{ cursor: 'pointer' }} size={[10, 0]}>
            <Avatar
              icon={<UserOutlined />}
              style={{ backgroundColor: '#f56a00' }}
            />
            Account
          </Space>
        </Dropdown>
      </Row>
    </AntHeader>
  );
});
