import React, { createContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { api } from '../services';
import { storageService } from '../services/storage.service';
import { commonActions } from '../store';

export const AuthContext = createContext({
  isAuthenticated: false,
  login: () => {},
  logout: () => {},
});

export const AuthContextProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const dispatch = useDispatch();

  const token = useSelector((state) => state.common.token);

  React.useEffect(() => {
    if (token) {
      storageService.setAccessToken(token);
      setIsAuthenticated(true);
    } else {
      storageService.removeAccessToken();
      setIsAuthenticated(false);
    }
  }, [token]);

  const login = (values) => {
    dispatch(commonActions.login(values));
  };

  const logout = () => {
    setIsAuthenticated(false);
    storageService.removeAccessToken();
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
