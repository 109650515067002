import React from 'react';
import {
  AppstoreOutlined,
  OrderedListOutlined,
  ProfileOutlined,
  PullRequestOutlined,
  SettingOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';

export const useSidebarProps = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [selectedKey, setSelectedKey] = React.useState('/');

  React.useEffect(() => {
    if (location.pathname.includes('/article')) {
      return setSelectedKey('/');
    }

    return setSelectedKey(location.pathname);
  }, [location]);

  let items = [
    {
      key: '/',
      icon: <AppstoreOutlined />,
      label: 'Services',
    },
    {
      key: '/projects',
      icon: <ProfileOutlined />,
      label: 'Projects',
    },
    {
      key: '/trainings',
      icon: <OrderedListOutlined />,
      label: 'Trainings',
    },
    {
      key: '/requests',
      icon: <PullRequestOutlined />,
      label: 'Requests',
    },
    {
      key: '/settings',
      icon: <SettingOutlined />,
      label: 'Settings',
    },
  ];

  const onClick = (evt) => {
    navigate(evt.key);
  };

  return { items, onClick, selectedKey };
};
