import './assets/css/normalize.css';
import './assets/css/styles.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import fetchIntercept from 'fetch-intercept';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';

import { App } from './app.component';
import reportWebVitals from './reportWebVitals';
import { AuthContextProvider } from './context/auth';
import { store } from './store';

import { version } from './config';
import { storageService } from './services';
import { ErrorBoundary } from './components/error-boundary/error-boundary.component';

/* Check application version */
const versionInStorage = storageService.getVersion();
if (versionInStorage !== version) {
  storageService.clear();
  storageService.setVersion(version);
}

fetchIntercept.register({
  request: function (url, config) {
    if (config?.headers)
      config.headers.Authorization = `Bearer ${storageService.getAccessToken()}`;
    return [url, config];
  },

  response: async (res) => {
    if (res.status === 401) {
      storageService.removeAccessToken();
      window.location.reload();
    }

    if (!res.ok) {
      const error = new Error('Oops! Something went wrong!');
      error.status = res.status;
      error.body = await res.json();

      throw error;
    }

    return res;
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ErrorBoundary>
    <BrowserRouter>
      <ReduxProvider store={store}>
        <AuthContextProvider>
          <App />
        </AuthContextProvider>
      </ReduxProvider>
    </BrowserRouter>
  </ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
