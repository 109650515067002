import * as yup from 'yup';
import { useAuth } from '../../hooks';

let loginSchema = yup.object().shape({
  username: yup.string().required('No email provided!'),
  password: yup.string().required('No password provided!'),
});

export const useLoginProps = () => {
  const { login } = useAuth();

  return { login, loginSchema };
};
