import { api } from '../../../services';

class ExpertsService {
  constructor(api) {
    this.api = api;
  }

  getProjects = () => this.api.get('/projects');
  addProject = (payload) => this.api.post('/projects', payload);
  updateProject = (payload) =>
    this.api.put(`/project/${payload.project_id}`, payload);
  deleteProject = (id) => this.api.delete(`/project/${id}`);

  addSphere = (payload) => this.api.post('/spheres', payload);
  updateSphere = (payload) =>
    this.api.put(`/sphere/${payload.sphere_id}`, payload);

  getExperts = () => this.api.get('/experts?page=1&count=1000');
  deleteExpert = (id) => this.api.delete(`/expert/${id}`);

  getPublications = (id) => this.api.get(`/publications?expert_id=${id}`);
  addPublication = (payload) => this.api.post('/publications', payload);
  updatePublication = (payload) =>
    this.api.put(`/publication/${payload.publication_id}`, payload);
  deletePublication = (id) => this.api.delete(`/publication/${id}`);

  getTeaching = (id) => this.api.get(`/teaching?expert_id=${id}`);
  addTeaching = (payload) => this.api.post('/teaching', payload);
  updateTeaching = (payload) =>
    this.api.put(`/teaching/${payload.teaching_id}`, payload);
  deleteTeaching = (id) => this.api.delete(`/teaching/${id}`);

  getMedia = (id) => this.api.get(`/media-enquires?expert_id=${id}`);
  addMedia = (payload) => this.api.post('/media-enquires', payload);
  updateMedia = (payload) =>
    this.api.put(`/media-enquires/${payload.media_enquirie_id}`, payload);
  deleteMedia = (id) => this.api.delete(`/media-enquires/${id}`);
}

export const expertsService = new ExpertsService(api);
