const stage = process.env.NODE_ENV;

const configs = {
  development: {
    apiUrl: 'https://api.mirpower.uz',
    imgUrl: 'https://api.mirpower.uz/upload/',
  },
  production: {
    apiUrl: 'https://api.mirpower.uz',
    imgUrl: 'https://api.mirpower.uz/upload/',
  },
};

export const config = configs[stage];
export const version = '1.0.0';
