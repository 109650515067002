export const formatHour = (date) => {
  const time = new Date(date);

  // Format time parts
  const hours = String(time.getHours()).padStart(2, '0');
  const minutes = String(time.getMinutes()).padStart(2, '0');

  // Combine date and time
  return `${hours}:${minutes}`;
};
