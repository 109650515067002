import { api } from '../../../services';

class ArticlesService {
  constructor(api) {
    this.api = api;
  }

  getTopics = () => this.api.get('/topics');
  addTopics = (payload) => this.api.post('/topics', payload);
  updateTopics = (payload) =>
    this.api.put(`/topic/${payload.topic_id}`, payload);
  deleteTopic = (id) => this.api.delete(`/topic/${id}`);

  getArticles = () => this.api.get('/services');
  getArticleById = (id) => this.api.get(`/service/${id}`);
  addServices = (payload) => this.api.post(`/services`, payload);
  updateServices = (payload) => this.api.put(`/service/${payload.id}`, payload);
  deleteServices = (id) => this.api.delete(`/service/${id}`);

  getComments = (id) => this.api.get(`/comments/${id}?all=all`);
  deleteComment = (id) => this.api.delete(`/comments/${id}`);
}

export const articlesService = new ArticlesService(api);
