import { createSlice } from '@reduxjs/toolkit';

const name = 'settings';

const initialState = {
  loading: false,
  users: [],
};

export const { actions: settingsActions, reducer: settingsReducer } =
  createSlice({
    name,
    initialState,
    reducers: {
      setLoading() {},
      setError(_, { payload }) {
        console.log(payload);
      },

      getUsers(state) {
        state.loading = true;
      },
      getUsersFulfilled(state, { payload }) {
        state.users = payload.data;
        state.loading = false;
      },

      postUsers() {},
      postUsersFulfilled(state, { payload }) {
        state.users = [...state.users, payload.data];
      },

      deleteUser() {},
      deleteUserFulfilled(state, { payload }) {
        state.users = state.users.filter(
          (user) => user.user_id !== payload.data.user_id
        );
      },
    },
  });
