import { articlesReducer } from '../modules/services/store/articles.slice';
import { expertsReducer } from '../modules/projects/store';
import { settingsReducer } from '../modules/settings/store/settings.slice';
import { membersReducer } from '../modules/trainings/store/members.slice';
import { commonReducer } from './common';
import { requestsReducer } from '../modules/requests/store';

export const rootReducer = {
  common: commonReducer,
  settings: settingsReducer,
  articles: articlesReducer,
  experts: expertsReducer,
  members: membersReducer,
  requests: requestsReducer,
};
