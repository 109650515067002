import { createSlice } from '@reduxjs/toolkit';

const name = 'requests';

const initialState = {
  loading: false,
  loadRequests: false,
  requests: [],
  loadTrainingRequests: false,
  trainingRequests: [],
};

export const { actions: requestsActions, reducer: requestsReducer } =
  createSlice({
    name,
    initialState,
    reducers: {
      setLoading() {},
      setError(_, { payload }) {
        console.log(payload);
      },

      getRequests(state) {
        state.loadRequests = true;
      },
      getRequestsFulfilled(state, { payload }) {
        state.requests = payload.data;
        state.loadRequests = false;
      },

      putRequest() {},
      putRequestFulfilled(state, { payload }) {
        state.requests = state.requests.map((request) => {
          if (request.request_id === payload.data.request_id) {
            return payload.data;
          }

          return request;
        });
      },

      deleteRequest() {},
      deleteRequestFulfilled(state, { payload }) {
        state.requests = state.requests.filter(
          (request) => request.request_id !== payload.data.request_id
        );
      },

      getTrainingRequests(state) {
        state.loadTrainingRequests = true;
      },
      getTrainingRequestsFulfilled(state, { payload }) {
        state.trainingRequests = payload.data;
        state.loadTrainingRequests = false;
      },

      putTrainingRequest() {},
      putTrainingRequestFulfilled(state, { payload }) {
        state.trainingRequests = state.trainingRequests.map((request) => {
          if (
            request.training_request_id === payload.data.training_request_id
          ) {
            return payload.data;
          }

          return request;
        });
      },

      deleteTrainingRequest() {},
      deleteTrainingRequestFulfilled(state, { payload }) {
        state.trainingRequests = state.trainingRequests.filter(
          (request) =>
            request.training_request_id !== payload.data.training_request_id
        );
      },
    },
  });
