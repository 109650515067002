import { createSlice } from '@reduxjs/toolkit';

const name = 'articles';

const initialState = {
  loading: false,
  loadTopics: false,
  topics: [],
  loadArticles: false,
  articles: [],
  loadArticle: false,
  article: {},
  isAdded: false,
  comments: [],
  loadComments: false,
};

export const { actions: articlesActions, reducer: articlesReducer } =
  createSlice({
    name,
    initialState,
    reducers: {
      setLoading() {},
      setError(_, { payload }) {
        console.log(payload);
      },

      getTopics(state) {
        state.loadTopics = true;
      },
      getTopicsFulfilled(state, { payload }) {
        state.topics = payload.data;
        state.loadTopics = false;
      },

      postTopics() {},
      postTopicsFulfilled(state, { payload }) {
        state.topics = [...state.topics, payload.data];
      },

      putTopic() {},
      putTopicFulfilled(state, { payload }) {
        state.topics = state.topics.map((topic) => {
          if (topic.topic_id === payload.data.topic_id) {
            return payload.data;
          }

          return topic;
        });
      },

      deleteTopic() {},
      deleteTopicFulfilled(state, { payload }) {
        state.topics = state.topics.filter(
          (topic) => topic.topic_id !== payload.data.topic_id
        );
      },

      getArticles(state) {
        state.loadArticles = true;
      },
      getArticlesFulfilled(state, { payload }) {
        state.articles = payload.data;
        state.loadArticles = false;
      },

      getArticleById() {},
      getArticleByIdFulfilled() {},

      postArticle() {},
      postArticleFulfilled(state, { payload }) {
        state.articles = [...state.articles, payload.data];
        state.isAdded = true;
      },

      putArticle() {},
      putArticleFulfilled(state, { payload }) {
        console.log(payload);
        state.articles = state.articles.map((article) => {
          if (article.article_id === payload.data.article_id) {
            return payload.data;
          }

          return article;
        });
        state.isAdded = true;
      },

      deleteArticle() {},
      deleteArticleFulfilled(state, { payload }) {
        state.articles = state.articles.filter(
          (article) => article.service_id !== payload.data.service_id
        );
      },

      setIsAdded(state, { payload }) {
        state.isAdded = payload;
      },

      getComments(state) {
        state.loadComments = true;
      },
      getCommentsFulfilled(state, { payload }) {
        console.log(payload);
        state.comments = payload.data;
        state.loadComments = false;
      },

      deleteComment() {},
      deleteCommentFulfilled(state, { payload }) {
        state.comments = state.comments.filter(
          (comment) => comment.comment_id !== payload.data.comment_id
        );
      },
    },
  });
