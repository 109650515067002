import { api } from './base.service';

class CommonService {
  constructor(api) {
    this.api = api;
  }

  login = (payload) => this.api.post('/auth/login', payload);
}

export const commonService = new CommonService(api);
