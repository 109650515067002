import { hoc } from '../../utils';
import { useErrorProps } from './result.props';
import { Result as AntResult } from 'antd';

/**
 * @description Result component
 * @example
 * <Result />
 * <Result status="error" />
 * <Result status="warning" />
 * ...
 */

export const Result = hoc(useErrorProps, ({ error, icon }) => {
  return (
    <AntResult
      icon={icon}
      style={{ width: '100%' }}
      status={error.status}
      title={error.title}
      subTitle={error.subTitle}
      extra={error.extra}
    />
  );
});
